import React from 'react'
import tw from 'twin.macro'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { CopyStyle, HeaderStyle, SubheadStyle } from './UI'
import { css } from '@emotion/react'

const FaqContent = ({ raw }) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h3 css={[HeaderStyle, tw`mb-5`]}>{children}</h3>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h4 css={[SubheadStyle, tw`mb-3`]}>{children}</h4>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p css={[CopyStyle, tw`inline-block mb-7`]}>{children}</p>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol
          css={[
            tw`list-decimal list-inside pl-5 mb-7`,
            css`
              ul,
              ol,
              p {
                margin-bottom: 0.25rem;
                display: inline;
              }
            `,
          ]}
        >
          {children}
        </ol>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul
          css={[
            tw`list-disc list-inside pl-5 mb-7`,
            css`
              ul,
              ol,
              p {
                margin-bottom: 0.25rem;
                display: inline;
              }
            `,
          ]}
        >
          {children}
        </ul>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li css={[tw`mb-1`]}>{children}</li>
      ),
    },
  }

  return <div>{documentToReactComponents(JSON.parse(raw), options)}</div>
}

export default FaqContent
